import React, { ChangeEvent } from "react";

const Checkbox: React.FC<{ title: string; value: boolean; onChange: (e: ChangeEvent<HTMLInputElement>) => void; undertext?: string }> = ({
	title,
	value,
	onChange,
	undertext = null,
}) => {
	return (
		<div className={`border border-gray-200 rounded dark:border-gray-300 mt-3 ps-4 ${undertext ? "py-2 md:py-3" : ""}`}>
			<div className="flex items-center">
				<input
					id={title}
					type="checkbox"
					name={title}
					className="w-5 h-5 border-gray-300 rounded accent-[#E5006B] hover:cursor-pointer"
					checked={value}
					onChange={onChange}
				/>
				<label htmlFor={title} className={`${undertext ? "" : "py-2 md:py-4"} w-full  ms-2 text-sm font-medium text-gray-900`}>
					{title}
				</label>
			</div>
			{undertext ? (
				<label htmlFor={title} className="text-xs">
					{undertext}
				</label>
			) : null}
		</div>
	);
};

export default Checkbox;
