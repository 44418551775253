import React, { ChangeEvent, HTMLInputTypeAttribute } from "react";
import { FormData } from "../types/types";
import InformationIcon from "./Icons/InformationMark";
import { Tooltip } from "react-tooltip";

interface TextInputProps {
	name: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, stepName: keyof FormData) => void;
	inputName: string;
	className: string;
	formIndex: keyof FormData;
	disabled: boolean | undefined;
	toolTip?: string[];
	autoComplete?: string;
	ref?: any;
	type?: HTMLInputTypeAttribute | undefined;
	placeholder?: string;
	max?: any;
	tabIndex?: number;
}

const TextInput: React.FC<TextInputProps> = ({
	name,
	value,
	onChange,
	inputName,
	className,
	formIndex,
	disabled,
	toolTip,
	autoComplete,
	ref,
	type = "text",
	placeholder = "",
	max,
	tabIndex,
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event, formIndex);
	};

	return (
		<>
			<div className="flex items-left">
				<label className="block text-gray-700 text-sm font-bold">{name}</label>
				{toolTip ? (
					<>
						<a data-tooltip-id="tooltip-id">
							<InformationIcon className="ml-2 w-5 h-5 stroke-black" />
						</a>
						<Tooltip id="tooltip-id">
							<div style={{ display: "flex", flexDirection: "column" }}>
								{toolTip.map((tip, index) => (
									<span key={index}>{tip}</span>
								))}
							</div>
						</Tooltip>
					</>
				) : null}
			</div>
			<input
				tabIndex={tabIndex}
				max={max}
				ref={ref}
				disabled={disabled}
				type={type}
				name={inputName}
				value={value}
				onChange={(e) => handleChange(e)}
				className={className}
				autoComplete={autoComplete}
				placeholder={placeholder}
			/>
		</>
	);
};

export default TextInput;
