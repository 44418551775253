import React, { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import CheckCircle from "./Components/Icons/CheckCircle";
import { DatePicker, DatesProvider, DatePickerProps, getMonthDays, DateValue, DatePickerInput, DateInput } from "@mantine/dates";
import axios, { AxiosError } from "axios";
import { FormData, FoundAddresses, ITechnicianBooking, Option, DawaAutocompleteItemResultItem } from "./types/types";
import moment from "moment";
import TextInput from "./Components/TextInput";
import Modal from "./Components/Modal";
import UploadFormData from "form-data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Checkbox from "./Components/Checkbox";
import "dayjs/locale/da";

const API = "https://integration.bwt.dk/api";
// const API = "http://localhost:8080/api";

const responsive = {
	desktop: {
		breakpoint: { max: 6000, min: 1024 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		partialVisibilityGutter: 40,
	},
};
const responsiveMobile = {
	desktop: {
		breakpoint: { max: 6000, min: 1024 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const initialFormState: FormData = {
	installationDetails: {
		model: "",
		error: "",
		serialNumber: "",
		message: "",
		errorDescription: "",
		installationDate: null,
	},
	customerDetails: {
		customerNumber: "",
		installationNumber: "",
		hasAggreement: null,
		hasAggreementType: null,
	},
	contactDetails: {
		firstName: "",
		lastName: "",
		email: "",
		address: "",
		postalCode: "",
		city: "",
		phone: "",
	},
	orderDetails: {
		date: null,
		time: null,
		callForQuickOrder: false,
		acceptedPayType: null,
		acceptedDetailsAreCorrect: null,
		wantServiceAggrement: null,
	},
};

const StepForm: React.FC = () => {
	const [formData, setFormData] = useState<FormData>(initialFormState);

	const [openIssues, setOpenIssues] = useState(true);
	const [issuesCompleted, setIssuesCompleted] = useState(false);
	const [openCustomerDetails, setOpenCustomerDetails] = useState(false);
	const [customerDetailsCompleted, setCustomerDetailsCompleted] = useState(false);
	const [openContactDetails, setOpenContactDetails] = useState(false);
	const [contactDetailsCompleted, setContactDetailsCompleted] = useState(false);
	const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
	const [time, setTime] = useState<String | null>(null);
	const [bookingId, setBookingId] = useState<number | null>(null);
	const [foundAddresses, setFoundAddresses] = useState<[FoundAddresses] | []>([]);
	const [addressAccepted, setAddressAccepted] = useState<boolean>(false);
	const [technicianAvailableDates, setTechnicianAvailableDates] = useState<[string] | []>([]);
	const [availableTimeSlots, setAvailableTimeSlots] = useState<[ITechnicianBooking] | []>([]);
	const [postalCodeVerified, setPostalCodeVerified] = useState<boolean>(false);
	const [technicianId, setTechnianId] = useState<number | null>(null);
	const [loadingServiceAggrement, setLoadingServiceAggrement] = useState<boolean>(false);
	const [serviceAggrementMsg, setServiceAggrementMsg] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isBookingLoading, setIsBookingLoading] = useState<boolean>(false);
	const [touchedPostalCode, setTouchedPostalCode] = useState(false);
	const [touchedEmail, setTouchedEmail] = useState(false);
	const [touchedPhone, setTouchedPhone] = useState(false);
	const [touchedFirstName, setTouchedFirstName] = useState(false);
	const [touchedLastName, setTouchedLastName] = useState(false);
	const [touchedCity, setTouchedCity] = useState(false);
	const [touchedSerialNumber, setTouchedSerialNumber] = useState(false);
	const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);
	const [tryghedPremiumText, setTryghedPremiumText] = useState("Vælg");
	const [tryghedPlusText, setTryghedPlusText] = useState("Vælg");
	const [bookingHasBeenSent, setBookingHasBeenSent] = useState(false);
	const [contactDetailErrors, setContactDetailErrors] = useState<string[] | []>([]);
	const [shallTypeInstallationDate, setShallTypeInstallationDate] = useState<boolean | null>(null);
	const [isOffshore, setIsOffshore] = useState<boolean>(false);
	const location = useLocation();

	const selectFaultTypeRef = useRef<HTMLSelectElement>(null);
	const contactDetailsRef = useRef<HTMLDivElement>(null);

	const [techniciansUnavailableInPostal, setTechniciansUnavailableInPostal] = useState<boolean>(false);

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		let { search } = location;
		let firstName: string,
			lastName: string,
			email: string,
			phone: string,
			address: string,
			postalCode: string,
			city: string,
			productCode: string,
			customerNumber: string,
			model: string;
		firstName = lastName = email = phone = address = postalCode = city = productCode = customerNumber = model = "";
		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("firstName" in searchJSON) {
				firstName = searchJSON["firstName"];
			}
			if ("lastName" in searchJSON) {
				lastName = searchJSON["lastName"];
			}
			if ("email" in searchJSON) {
				email = searchJSON["email"];
			}
			if ("phone" in searchJSON) {
				phone = searchJSON["phone"];

				phone = phone.replace(/\s/g, "");
				// if starts with +45 or 0045, then remove it
				if (phone.startsWith("+45")) {
					phone = phone.slice(3);
				} else if (phone.startsWith("0045")) {
					phone = phone.slice(4);
				}
				// if starts with 45 and length is 10, then remove 45
				if (phone.startsWith("45") && phone.length === 10) {
					phone = phone.slice(2);
				}
			}
			if ("address" in searchJSON) {
				address = searchJSON["address"];
			}
			if ("postalCode" in searchJSON) {
				postalCode = searchJSON["postalCode"];
				verifyPostalCode(postalCode);
			}
			if ("city" in searchJSON) {
				city = searchJSON["city"];
			}
			if ("productCode" in searchJSON) {
				productCode = searchJSON["productCode"].toUpperCase();
			}
			if ("customerNumber" in searchJSON) {
				customerNumber = searchJSON["customerNumber"];
			}
			if ("model" in searchJSON) {
				model = searchJSON["model"];
			}

			if (firstName || lastName || email || phone || address || postalCode || city || productCode) {
				setFormData(initialFormState);
			}
		}
	}, []);

	// Hver gang brugeren skifter dato, så hent ledige tider på den gældende dato
	useEffect(() => {
		console.log(getMonthDays);
		const fetchData = async () => {
			const date = formData.orderDetails.date;
			const postalCode = formData.contactDetails.postalCode;
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : ""; // in the format YYYY-MM-DD
				const response = await axios.get(`${API}/technicianbooking/all/${technicianId}/${_date}/${postalCode}/${formData.installationDetails.model}`);
				setTime(null);
				setBookingId(null);
				setAvailableTimeSlots(response.data.teknikerBookings);
				console.log("available time slots:", response.data.teknikerBookings);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.orderDetails.date !== null && isModalOpen !== true && technicianId !== null) {
			fetchData();
		}
	}, [formData.orderDetails.date, technicianId, formData.contactDetails.postalCode]);

	// Hent tekniker pr postnummer, hver gang brugeren skifter postnummer
	useEffect(() => {
		const fetchData = async () => {
			const postalCode = formData.contactDetails.postalCode;
			console.log("Fetching technician userId", postalCode);
			try {
				const response = await axios.get(`${API}/technicianpostals/${postalCode}`);
				console.log(response);

				if (response.data.user.length > 0) {
					setTechnianId(response.data.user[0].userId);
					setTechniciansUnavailableInPostal(false);
				} else {
					setTechnianId(null);
					setTechniciansUnavailableInPostal(true);
				}
			} catch (error: any) {
				setTechnianId(null);
				setTechniciansUnavailableInPostal(true);
			}
		};
		if (postalCodeVerified === true) {
			fetchData();
		}
	}, [formData.contactDetails.postalCode, formData.contactDetails.address]);

	// Her hender vi teknikerens ledige datoer
	useEffect(() => {
		const fetchData = async () => {
			try {
				const postalCode = formData.contactDetails.postalCode;

				const serviceType = formData.installationDetails.error == "almservice" ? "servicebooking" : "errorbooking";

				const response = await axios.get(
					`${API}/technicianbooking/availabledates/${technicianId}/${postalCode}/${formData.installationDetails.model}/${serviceType}`
				);
				setTechnicianAvailableDates(response.data.teknikerBookingDates);
				console.log(response.data);
				console.log("Henter datoer igen for teknikeren " + technicianId + " og postnummer " + postalCode);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};

		if (technicianId !== null && formData.orderDetails.date === null) {
			fetchData();
		}
	}, [technicianId, formData.orderDetails.date, formData.contactDetails.postalCode, formData.contactDetails.address]); // Empty array as the second argument to run effect only once (on mount)

	useEffect(() => {
		let errors: string[] = [];
		if (touchedEmail && !validateEmail(formData.contactDetails.email)) {
			if (formData.contactDetails.email !== "") {
				errors.push("Email er ikke gyldig");
			} else {
				errors.push("Email feltet er påkrævet");
			}
		}

		if (touchedPhone && !validatePhone(formData.contactDetails.phone)) {
			if (formData.contactDetails.phone !== "") {
				errors.push("Telefonnummer er ikke gyldigt. Det skal være 8 cifret");
			} else {
				errors.push("Telefonnummer er påkrævet");
			}
		}

		if (touchedFirstName && formData.contactDetails.firstName === "") {
			errors.push("Fornavn er påkrævet");
		}

		if (touchedLastName && formData.contactDetails.lastName === "") {
			errors.push("Efternavn er påkrævet");
		}

		if (touchedCity && formData.contactDetails.city === "") {
			errors.push("By er påkrævet");
		}

		if (touchedSerialNumber && validateProductCode(formData.installationDetails.serialNumber) === false) {
			if (formData.installationDetails.serialNumber !== "") {
				errors.push("Produkt kode er ikke gyldig. Den skal være i formatet XXXX-XXXX");
			} else {
				errors.push("Produkt kode er påkrævet");
			}
		}

		if (touchedPostalCode && !postalCodeVerified) {
			if (formData.contactDetails.postalCode === "") {
				errors.push("Postnummer er påkrævet");
				setIsPostalCodeValid(false);
			} else {
				errors.push("Postnummer er ikke gyldigt");
				setIsPostalCodeValid(false);
			}
		}
		setContactDetailErrors(errors);
	}, [formData.contactDetails, formData.installationDetails.serialNumber, postalCodeVerified]);

	useEffect(() => {
		const postal = formData.contactDetails.postalCode;
		setIsOffshore(
			postal == "8305" ||
				postal == "5985" ||
				postal == "5970" ||
				postal == "5960" ||
				postal == "4592" ||
				postal == "4930" ||
				postal == "4944" ||
				postal == "3770" ||
				postal == "3790" ||
				postal == "3782" ||
				postal == "3760" ||
				postal == "3700" ||
				postal == "3720" ||
				postal == "3751" ||
				postal == "3740" ||
				postal == "3730"
				? true
				: false
		);
	}, [formData.contactDetails.postalCode]);

	const findServiceAggrements = async () => {
		setLoadingServiceAggrement(true);
		try {
			const response = await axios.post(`${API}/privateserviceaggrements`, {
				firstName: formData.contactDetails.firstName,
				lastName: formData.contactDetails.lastName,
				email: formData.contactDetails.email,
				phone: formData.contactDetails.phone,
				address: formData.contactDetails.address,
				postalCode: formData.contactDetails.postalCode,
				city: formData.contactDetails.city,
				productCode: formData.installationDetails.serialNumber,
			});
			console.log(response.data);

			if (response.data.msg === "Vi kan se at du har en BWT tryghedsaftale, som betyder at dit servicebesøg er dækket.") {
				// Tjek hvilken tryghedsaftale kunden har
				let hasAggrementType: string | null = null;
				let customerNumber: string = response.data.serviceAgreements.customerNumber;
				if (response.data.serviceAgreements.type === "TN") {
					hasAggrementType = "premium";
				} else if (response.data.serviceAgreements.type === "TP") {
					hasAggrementType = "plus";
				} else if (response.data.serviceAgreements.type === "TH") {
					hasAggrementType = "basic";
				}

				let message: string | null = "Du er allerede dækket af en BWT tryghed " + hasAggrementType + " aftale, som betyder at dit servicebesøg er dækket.";

				setServiceAggrementMsg(message);
				setShallTypeInstallationDate(false);

				setOpenContactDetails(false);
				setContactDetailsCompleted(true);
				setOpenCustomerDetails(true);
				contactDetailsRef.current?.scrollIntoView();

				setFormData((prevData) => ({
					...prevData,
					["customerDetails"]: {
						...prevData["customerDetails"],
						["hasAggreement"]: true,
						["hasAggreementType"]: hasAggrementType,
						["customerNumber"]: customerNumber,
					},
				}));
			} else {
				setServiceAggrementMsg(response.data.msg);
				setShallTypeInstallationDate(true);
			}
			setLoadingServiceAggrement(false);
		} catch (error) {
			console.log(error);
			setServiceAggrementMsg("Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?");
			setFormData((prevData) => ({
				...prevData,
				["customerDetails"]: {
					...prevData["customerDetails"],
					["hasAggreement"]: false,
					["hasAggreementType"]: null,
				},
			}));
			setShallTypeInstallationDate(true);
		}
		setLoadingServiceAggrement(false);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, stepName: keyof FormData) => {
		let value = e.target.value;

		if (e.target.name === "address") {
			findAddress(e.target.value);
		}

		if (e.target.name === "postalCode") {
			setTouchedPostalCode(true);
			verifyPostalCode(e.target.value);
		}

		if (e.target.name === "email") {
			setTouchedEmail(true);
		}

		if (e.target.name === "phone") {
			// remove spaces
			value = value.replace(/\s/g, "");
			// if starts with +45 or 0045, then remove it
			if (value.startsWith("+45")) {
				value = value.slice(3);
			} else if (value.startsWith("0045")) {
				value = value.slice(4);
			}
			// if starts with 45 and length is 10, then remove 45
			if (value.startsWith("45") && value.length === 10) {
				value = value.slice(2);
			}
			setTouchedPhone(true);
		}

		if (e.target.name === "firstName") {
			setTouchedFirstName(true);
		}

		if (e.target.name === "lastName") {
			setTouchedLastName(true);
		}

		if (e.target.name === "city") {
			setTouchedCity(true);
		}

		if (e.target.name === "serialNumber") {
			setTouchedSerialNumber(true);
			value = value.toUpperCase();

			const oldValue = formData.installationDetails.serialNumber;

			// check if the - is removed
			if (value.length === 4 && oldValue.length === 5) {
				value = value;
			} else {
				if (value.length === 4) {
					value = value + "-";
				}
			}
		}

		if (e.target.name !== "installationDate") {
			setShallTypeInstallationDate(false);
			if (stepName === "installationDetails") {
				setFormData((prevData) => ({
					...prevData,
					[stepName]: {
						...prevData[stepName],
						[e.target.name]: value,
						["installationDate"]: null,
					},
				}));
			} else {
				setFormData((prevData) => ({
					...prevData,
					[stepName]: {
						...prevData[stepName],
						[e.target.name]: value,
					},
					["installationDetails"]: {
						...prevData["installationDetails"],
						["installationDate"]: null,
					},
				}));
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: value,
				},
			}));
		}
	};

	const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>, stepName: keyof FormData) => {
		// If the model has changed, then reset the error to an empty string
		if (e.target.name === "model") {
			console.log("Det er model");
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: e.target.value,
					["error"]: "",
				},
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: e.target.value,
				},
			}));
		}
	};

	function formatDateYmd(date: Date) {
		let day: any = date.getDate();
		let month: any = date.getMonth() + 1; // Month is zero-based
		let year: any = date.getFullYear() + 2;

		// Add leading zero if day or month is less than 10
		day = day < 10 ? "0" + day : day;
		month = month < 10 ? "0" + month : month;

		return year + "-" + month + "-" + day;
	}

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		setIsBookingLoading(true);

		const body = {
			userId: technicianId,
			available: 0,
			date: formData.orderDetails.date ? moment(formData.orderDetails.date.toISOString().split("T")[0]).format("YYYY-MM-DD") : null,
			timeSlot: time,
			source: "webform",
			customerNumber: formData.customerDetails.customerNumber,
			hasServiceAggrement: formData.customerDetails.hasAggreement ? 1 : 0,
			wantServiceAggrement: formData.orderDetails.wantServiceAggrement !== "none" ? 1 : 0,
			wantServiceAggrementType: formData.orderDetails.wantServiceAggrement,
			model: formData.installationDetails.model,
			errorType: formData.installationDetails.error,
			firstName: formData.contactDetails.firstName,
			lastName: formData.contactDetails.lastName,
			email: formData.contactDetails.email,
			phone: formData.contactDetails.phone,
			address: formData.contactDetails.address,
			city: formData.contactDetails.city,
			postal: formData.contactDetails.postalCode,
			productCode: formData.installationDetails.serialNumber,
			acceptedContactByBWT: formData.orderDetails.callForQuickOrder,
			message: formData.installationDetails.message,
			installationDate: formData.installationDetails.installationDate
				? moment(formData.installationDetails.installationDate.toISOString().split("T")[0]).format("YYYY-MM-DD")
				: null,
			errorDescription: formData.installationDetails.errorDescription,
			status: "RECEIVED",
			acceptedDetailsAreCorrect: 1,
		};

		console.log(body);

		try {
			const response = await axios.post(API + "/technicianbooking/book/" + bookingId, body);
			console.log(response);
			setIsBookingLoading(false);
			setIsModalOpen(false);
			resetForm();
			setBookingHasBeenSent(true);
		} catch (error) {
			console.log(error);
			setIsBookingLoading(false);
			setIsModalOpen(false);
		}
	};

	// function that resets the form to the initial state
	const resetForm = () => {
		setFormData(initialFormState);
		setOpenIssues(true);
		setIssuesCompleted(false);
		setOpenCustomerDetails(false);
		setCustomerDetailsCompleted(false);
		setOpenContactDetails(false);
		setContactDetailsCompleted(false);
		setOpenDateTimePicker(false);
		setTime(null);
		setBookingId(null);
		setFoundAddresses([]);
		setAddressAccepted(false);
		setTechnicianAvailableDates([]);
		setAvailableTimeSlots([]);
		setPostalCodeVerified(false);
		setTechnianId(null);
		setLoadingServiceAggrement(false);
		setServiceAggrementMsg("");
	};

	const hidden = (open: boolean) => {
		return open === true ? "" : " hidden";
	};

	function getDateInFuture(amount: number): Date {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() + amount);
		return yesterday;
	}

	const getDayProps: DatePickerProps["getDayProps"] = (_date) => {
		// If the day is saturday or sunday, then disable it
		if (_date.getDay() === 6 || _date.getDay() === 0) {
			return { disabled: true };
		}

		// console.log(technicianAvailableDates);

		if (technicianAvailableDates === undefined) {
			return {};
		}

		if (technicianAvailableDates.length <= 0) {
			return {};
		}

		const available = technicianAvailableDates.some((item) => item === _date.toISOString().split("T")[0]);

		const date = formData.orderDetails.date;

		if (date !== null && _date !== null) {
			if (_date.toISOString().split("T")[0].replace(/-/g, "") === date.toISOString().split("T")[0].replace(/-/g, "")) {
				return {
					style: {
						backgroundColor: "#00174B",
						color: "var(--mantine-color-white)",
						selected: "red",
					},
				};
			}
		}

		if (available) {
			return {
				style: {
					backgroundColor: "#00174B",
					opacity: 0.72,
					color: "var(--mantine-color-white)",
					selected: "red",
				},
			};
		} else {
			return { disabled: true };
		}
	};

	// Hvis året er det samme som i dag, så gør teksten blå og fed på årsknapperne
	const getYearControlProps: DatePickerProps["getYearControlProps"] = (date) => {
		if (date.getFullYear() === new Date().getFullYear()) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getFullYear() === new Date().getFullYear() + 1) {
			return { disabled: true };
		}

		return {};
	};

	// Hvis måneden er februar, så gør teksten blå og fed på månedknapperne
	const getMonthControlProps: DatePickerProps["getMonthControlProps"] = (date) => {
		if (date.getMonth() === 1) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getMonth() === 5) {
			return { disabled: true };
		}

		return {};
	};

	// Ud fra hvad brugeren skriver i adressefeltet, så find adresser fra Dawa
	const findAddress = async (address: string) => {
		try {
			const response = await axios.get(`https://api.dataforsyningen.dk/adresser/autocomplete?q=${address}&per_side=5`);

			console.log(response.data);
			if (response.data.length > 0) {
				const tempAddresses: [{ address: string; city: string; postalCode: string }] = response.data.map((item: DawaAutocompleteItemResultItem) => {
					return { address: item.tekst, city: item.adresse.postnrnavn, postalCode: item.adresse.postnr };
				});
				console.log(tempAddresses);

				setFoundAddresses(tempAddresses);
			} else {
				setFoundAddresses([]);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Ud fra hvad brugeren skriver i postnummerfeltet, så kald API'en for at verificere postnummeret
	const verifyPostalCode = async (postalCode: string) => {
		// Hvis postnummeret er 4 cifre langt og alle cifre er tal, så kald API'et
		if (postalCode.length === 4 && !isNaN(Number(postalCode))) {
			try {
				const response = await axios.get(`https://api.dataforsyningen.dk/postnumre?nr=${postalCode}`);

				if (response.data.length === 1) {
					setPostalCodeVerified(true);
					return true;
				} else {
					setPostalCodeVerified(false);
					return false;
				}
			} catch (error) {
				setPostalCodeVerified(false);
				return false;
			}
		} else {
			setPostalCodeVerified(false);
			return false;
		}
	};

	// Når brugeren vælger en adresse fra listen, så opdater formdata med den valgte adresse
	const pickAddress = (item: FoundAddresses) => {
		setPostalCodeVerified(true);
		setAddressAccepted(true);
		setFoundAddresses([]);
		setFormData((prevData) => ({
			...prevData,
			["contactDetails"]: {
				...prevData["contactDetails"],
				["address"]: item.address,
				["city"]: item.city,
				["postalCode"]: item.postalCode,
			},
		}));
	};

	// Define options for the second select based on the selected anlæg
	const fejlOptions: Record<string, Option[]> = {
		aqalife: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "overcure",
				label: "Anlæg melder Overcure / Überstrom",
			},
			{
				value: "service",
				label: "Anlæg kalder på service eller ”Wartung”",
			},
			{
				value: "skylleriafloeb",
				label: "Anlæg skyller direkte i afløbet",
			},
			{
				value: "taellerikkened",
				label: "Anlæg tæller ikke ned i liter på display",
			},
			{
				value: "saltbeholdertor",
				label: "Saltbeholderen er helt tør",
			},
			{ value: "darligsmag", label: "Dårlig smag eller smager af salt" },
			{ value: "saltskaeg", label: "Der er saltudfældninger/saltskæg i karet" },
			{ value: "maalerhaardtvand", label: "Anlæg måler hårdt vand" },
			{ value: "other", label: "Andet" },
		],
		aqabasic: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "p05error",
				label: "Anlæg melder P05/EBC fejl",
			},
			{
				value: "service",
				label: "Anlæg blinker i displayet",
			},
			{
				value: "skylleriafloeb",
				label: "Anlæg skyller direkte i afløbet",
			},
			{
				value: "taellerikkened",
				label: "Mit anlæg tæller ikke ned i liter på display",
			},
			{
				value: "saltbeholdertor",
				label: "Saltbeholderen er helt tør",
			},
			{ value: "maalerikkehaardheder", label: "Måler ikke hårdheder selvom indstillingerne er korrekte. Afviger 3-4 hårdheder" },
			{ value: "darligsmag", label: "Dårlig smag eller smager af salt" },
			{ value: "saltskaeg", label: "Der er saltudfældninger/saltskæg i karet" },
			{ value: "other", label: "Andet" },
		],
		bwtperla: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "ekspertservice",
				label: "Anlæg melder ”Ekspert Service” på display eller i app",
			},
			{
				value: "positionsfejlmotorfejl",
				label: "Mit anlæg melder Positionsfejl, motorfejl",
			},
			{
				value: "vandisalgbeholder",
				label: "Der er vand i saltbeholderen",
			},
			{
				value: "rutinemaessigvedligehold",
				label: "Anlæg melder ”Rutinemæssig vedligehold” i displayet eller på min app",
			},
			{
				value: "regenereringsvolumenikkenået",
				label: "Mit anlæg melder regenereringsvolumen ikke opnået",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "ingeninternet",
				label: "Anlæg melder ingen internetforbindelse",
			},
			{
				value: "kanikkeforbindeapp",
				label: "Anlæg kan ikke forbinde til app",
			},
			{
				value: "ingenaktiveringsmail",
				label: "Jeg har ikke modtaget nogen aktiveringsmail",
			},
			{
				value: "elektronikfejl",
				label: "Elektronikfejl",
			},
			{
				value: "aqawatchudlost",
				label: "AQA Watch udløst",
			},
			{
				value: "aqastopliterudlost",
				label: "Aqa Stop liter udløst",
			},
			{
				value: "aqastopsensorudlost",
				label: "Aqa Stop sensor udløst",
			},
			{ value: "other", label: "Andet" },
		],
		bwtperlaone: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "ekspertservice",
				label: "Anlæg melder ”Ekspert Service” på display eller i app",
			},
			{
				value: "positionsfejlmotorfejl",
				label: "Mit anlæg melder Positionsfejl, motorfejl",
			},
			{
				value: "vandisalgbeholder",
				label: "Der er vand i saltbeholderen",
			},
			{
				value: "rutinemaessigvedligehold",
				label: "Anlæg melder ”Rutinemæssig vedligehold” i displayet eller på min app",
			},
			{
				value: "regenereringsvolumenikkenået",
				label: "Mit anlæg melder regenereringsvolumen ikke opnået",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "ingeninternet",
				label: "Anlæg melder ingen internetforbindelse",
			},
			{
				value: "kanikkeforbindeapp",
				label: "Anlæg kan ikke forbinde til app",
			},
			{
				value: "ingenaktiveringsmail",
				label: "Jeg har ikke modtaget nogen aktiveringsmail",
			},
			{
				value: "elektronikfejl",
				label: "Elektronikfejl",
			},
			{
				value: "aqawatchudlost",
				label: "AQA Watch udløst",
			},
			{
				value: "aqamaxflowudlost",
				label: "AQA Max Flow udløst",
			},
			{ value: "other", label: "Andet" },
		],
	};

	const handleDateChanged = (date: DateValue) => {
		setFormData((prevData) => ({
			...prevData,
			["orderDetails"]: {
				...prevData["orderDetails"],
				["date"]: date,
			},
		}));
	};

	const [imageState, setImageState] = useState({
		currentImages: [] as File[],
		error: "",
	});

	function handleDeleteImage(imageName: any) {
		setImageState({
			...imageState,
			currentImages: imageState.currentImages.filter((image) => image.name !== imageName),
		});
	}

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files === null) return;
		if (imageState.currentImages.length >= 15) {
			setImageState({ ...imageState, error: "You have already selected the max of 15 images." });
			return;
		}
		setImageState({ ...imageState, currentImages: [...imageState.currentImages, event.target.files[0]] });
	}

	function handleOnDrop(event: any) {
		event.preventDefault();
		event.stopPropagation();
		if (imageState.currentImages.length >= 15) {
			setImageState({ ...imageState, error: "You have already selected the max of 15 images." });
			return;
		}
		setImageState({
			...imageState,
			currentImages: imageState.currentImages.concat(Array.from(event.dataTransfer.files)),
		});
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.remove("ring-2", "ring-blue-500");
	}

	function handleDragOver(event: any) {
		event.stopPropagation();
		event.preventDefault();
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.add("ring-2", "ring-blue-500");
	}

	function handleDragEnter(event: any) {
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.add("ring-2", "ring-blue-500");
	}

	function handleDragLeave(even: any) {
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.remove("ring-2", "ring-blue-500");
	}

	function uploadImages() {
		if (imageState.currentImages.length > 0) {
			const files: File[] = imageState.currentImages;

			const formData = new UploadFormData();
			files.forEach((file, index) => {
				formData.append(`image${index}`, file);
			});

			fetch("https://booking.bwt.dk/upload.images.php", {
				method: "POST",
				body: JSON.stringify(formData),
			})
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function getAggrementPrice(model: string, type: string): number {
		if (model === "aqalife" || model === "bwtperla" || model === "bwtperlahome") {
			if (type === "premium") {
				return 209;
			} else if (type === "plus") {
				return 169;
			} else if (type === "basic") {
				return 89;
			}
		} else if (model === "aqabasic") {
			if (type === "premium") {
				return 169;
			} else if (type === "plus") {
				return 129;
			} else if (type === "basic") {
				return 69;
			}
		} else if (model === "bwtperlaone") {
			if (type === "premium") {
				return 179;
			} else if (type === "plus") {
				return 139;
			} else if (type === "basic") {
				return 79;
			}
		}
		return 0;
	}

	function serviceAggrementIsValid() {
		if (
			serviceAggrementMsg ===
			"Du er allerede dækket af en BWT tryghed " + formData.customerDetails.hasAggreementType + " aftale, som betyder at dit servicebesøg er dækket."
		) {
			return false;
		} else if (
			(serviceAggrementMsg === "Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?" ||
				serviceAggrementMsg ===
					"Vi kan se du tidligere har haft en BWT tryghedsaftale. Ønsker du at genoptage denne eller vil du betale for det enkelte besøg?") &&
			formData.orderDetails.wantServiceAggrement !== null
		) {
			return false;
		} else {
			return true;
		}
	}

	function validateEmail(email: string) {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	// validate phone number
	function validatePhone(phone: string) {
		const re = /^\d{8}$/;
		return re.test(phone);
	}

	// validate that the productcode is in the format XXXX-XXXX where X can be a number or a letter in uppercase
	function validateProductCode(productCode: string) {
		const re = /^[A-ZÆØÅ0-9]{4}-[A-ZÆØÅ0-9]{4}$/;
		return re.test(productCode);
	}

	function isContactDetailsValid() {
		if (formData.contactDetails.firstName === "") {
			return true;
		}

		if (formData.contactDetails.lastName === "") {
			return true;
		}

		if (validateEmail(formData.contactDetails.email) === false) {
			return true;
		}

		if (validatePhone(formData.contactDetails.phone) === false) {
			return true;
		}

		if (formData.contactDetails.address === "") {
			return true;
		}

		if (formData.contactDetails.postalCode === "") {
			return true;
		}

		if (formData.contactDetails.city === "") {
			return true;
		}

		if (formData.installationDetails.serialNumber === "") {
			return true;
		}

		if (validateProductCode(formData.installationDetails.serialNumber) === false) {
			return true;
		}

		if (validateEmail(formData.contactDetails.email) === false) {
			return true;
		}

		if (
			shallTypeInstallationDate === true &&
			(formData.installationDetails.installationDate === null || formData.installationDetails.installationDate === undefined)
		) {
			return true;
		}

		if (technicianId === null) {
			return true;
		}

		if (isOffshore == true) {
			return true;
		}

		return false;
	}

	const executeRef = () => {
		selectFaultTypeRef.current?.scrollIntoView();
	};

	function isEligibleForFreeServiceAggrement() {
		const installationDateStr = formData?.installationDetails?.installationDate;
		if (!installationDateStr) {
			return false;
		}

		const installationDate = new Date(installationDateStr);
		const today = new Date();

		// Check if installation date is invalid or in the future
		if (isNaN(installationDate.getTime()) || installationDate > today) {
			return false;
		}

		// Calculate the difference in milliseconds
		const timeDiff = today.getTime() - installationDate.getTime();

		// Convert milliseconds to years
		const diffYears = timeDiff / (1000 * 3600 * 24 * 365.25);

		return diffYears <= 2;
	}

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<>
			<div className="max-w-3xl mx-auto mt-4 mb-4 md:mb-6 px-1 md:px-0">
				<div className="max-h-8 md:max-h-12 flex h-screen">
					<div>
						<img className="max-h-8 md:max-h-14" src="./BWT_Logo_with_Subline_Positive_360x162.png" alt="bwtchangetheworldlogo" />
					</div>
					<div className="m-auto mr-0 ">
						<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">Bestil BWT servicetekniker</h3>
					</div>
				</div>
			</div>
			{!bookingHasBeenSent ? (
				<div>
					<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
						<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							<StepHeader
								num="1"
								name="Fejltype"
								completed={!openIssues}
								onClick={() => {
									// Open the step again if it is not open
									if (!openIssues) {
										setOpenIssues(true);
										setOpenContactDetails(false);
										setIssuesCompleted(false);
										setContactDetailsCompleted(false);
										setOpenCustomerDetails(false);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{openIssues && issuesCompleted === false ? (
									<svg className={`h-5 w-5 flex-none text-gray-400 `} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`mt-1 text-gray-600 ${hidden(openIssues && issuesCompleted === false)} mt-6`}>
							<label className="block text-gray-700 text-sm font-bold mb-4">Hvilket anlæg ønsker du at bestille en tekniker til?</label>

							<form>
								<div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
									<div
										key={1}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "bwtperla" ? "border-[#00174B]" : ""
										}`}
										onClick={() => {
											setFormData((prevData) => ({
												...prevData,
												["installationDetails"]: {
													...prevData["installationDetails"],
													["model"]: "bwtperla",
													["error"]: "",
												},
											}));
											executeRef();
										}}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/perla.webp" alt="" />
										<h3 className="text-md font-semibold my-2">PERLA</h3>
									</div>
									<div
										key={2}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "bwtperlaone" ? "border-[#00174B]" : ""
										}`}
										onClick={() => {
											setFormData((prevData) => ({
												...prevData,
												["installationDetails"]: {
													...prevData["installationDetails"],
													["model"]: "bwtperlaone",
													["error"]: "",
												},
											}));
											executeRef();
										}}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/perlaone.webp" alt="" />
										<h3 className="text-md font-semibold my-2">PERLA ONE</h3>
									</div>
									<div
										key={3}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "aqalife" ? "border-[#00174B]" : ""
										}`}
										onClick={() => {
											setFormData((prevData) => ({
												...prevData,
												["installationDetails"]: {
													...prevData["installationDetails"],
													["model"]: "aqalife",
													["error"]: "",
												},
											}));
											executeRef();
										}}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/aqalife.webp" alt="" />
										<h3 className="text-md font-semibold my-2">AQA LIFE</h3>
									</div>
									<div
										key={4}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "aqabasic" ? "border-[#00174B]" : ""
										}`}
										onClick={() => {
											setFormData((prevData) => ({
												...prevData,
												["installationDetails"]: {
													...prevData["installationDetails"],
													["model"]: "aqabasic",
													["error"]: "",
												},
											}));
											executeRef();
										}}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/basic.webp" alt="" />
										<h3 className="text-md font-semibold my-2">AQA BASIC</h3>
									</div>
								</div>
								<div className={`grid grid-cols-1 gap-2 mb-2`}>
									<label className="block text-gray-700 text-sm font-bold">Hvad er problemet med dit anlæg?</label>
									<select
										ref={selectFaultTypeRef}
										id="fejl"
										className="w-full border p-2"
										value={formData.installationDetails.error}
										onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleSelectChange(event, "installationDetails")}
										name="error"
									>
										{formData.installationDetails.model != "" ? (
											<>
												{fejlOptions[formData.installationDetails.model].map((option, index) => (
													// <option disabled={option.value === ""} key={option.value} value={option.value}>
													<option key={index} value={option.value}>
														{option.label}
													</option>
												))}
											</>
										) : null}
									</select>
								</div>

								{/* If a model i picked and error is picked, show possible solution */}
								{formData.installationDetails.error !== "" && formData.installationDetails.model !== "" ? (
									<Solution fejlOptions={fejlOptions} formData={formData} />
								) : null}

								{formData.installationDetails.error === "other" ? (
									<div className="relative flex mb-4">
										<textarea
											id="errorDescription"
											rows={2}
											className="block p-2.5 w-full text-sm mt-3 text-gray-900 bg-gray-50 rounded border border-gray-300 dark:border-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
											placeholder="Beskriv problemet du har med dit anlæg her"
											value={formData.installationDetails.errorDescription}
											name="errorDescription"
											onChange={(e) => handleChange(e, "installationDetails")}
										></textarea>
									</div>
								) : null}

								{/* 							
							<div>
								<label className="block text-gray-700 text-sm font-bold">Tilføj venligst nogle billeder af fejlen på anlægget</label>
								<div className="bg-gray-100 flex my-2 justify-center">
									<form
										onDragOver={handleDragOver}
										onDragEnter={handleDragEnter}
										onDragLeave={handleDragLeave}
										onDrop={(event) => handleOnDrop(event)}
										id="dragAndDropArea"
										className="flex justify-center flex-1 p-2"
									>
										<input className="hidden" type="file" id="file" accept="image/*" onChange={handleImageChange}></input>
										<label htmlFor="file">
											<strong className="text-blue-600 cursor-pointer hover:text-blue-500">Vælg fil</strong> eller træk og slip filer her
										</label>
									</form>
								</div>
							</div>

							{imageState.error.length ? <p className="bg-red-400 text-white p-2">{imageState.error}</p> : ""}
							{imageState.currentImages.length ? (
								<div className="flex flex-row flex-wrap bg-gray-100 p-2">
									{imageState.currentImages.map((image: File) => {
										return (
											<div className="mx-2 relative">
												<img src={URL.createObjectURL(image)} className="max-h-32" />
												<div onClick={() => handleDeleteImage(image.name)} className="absolute top-0 right-0">
													<XMark className="bg-black text-white hover:text-[#E5006B] cursor-pointer h-4 w-4" />
												</div>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}

							<button type="button" onClick={uploadImages} className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]">
								Upload
							</button> */}

								<button
									type="button"
									disabled={
										formData.installationDetails.model === "" ||
										formData.installationDetails.error === "" ||
										(formData.installationDetails.error === "other" && formData.installationDetails.errorDescription === "")
									}
									onClick={() => {
										// setOpenCustomerDetails(true);
										setOpenIssues(false);

										setOpenContactDetails(true);
										// setOpenCustomerDetails(false);
										// setCustomerDetailsCompleted(true);
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</form>
						</div>
					</div>

					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 ">
						<div ref={contactDetailsRef} className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							{/* <a href="#" className="font-semibold text-gray-900 flex items-center transition-transform duration-600"> */}
							<StepHeader
								num="2"
								name="Kontaktinformation"
								completed={!openContactDetails && !openIssues}
								onClick={() => {
									// Open the step again if it is not open
									if (!openIssues) {
										setOpenIssues(false);
										setOpenContactDetails(true);
										setIssuesCompleted(true);
										setContactDetailsCompleted(false);
										setOpenCustomerDetails(false);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{contactDetailsCompleted === false ? (
									<svg
										className={`h-5 w-5 flex-none text-gray-400 transform ${openContactDetails ? "rotate-0" : "rotate-180"}`}
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`${hidden(openContactDetails && contactDetailsCompleted === false)} mt-6`}>
							<form>
								<div className="grid grid-cols-2 gap-2">
									<div className="mb-2 md:mb-4">
										<TextInput
											tabIndex={1}
											disabled={false}
											name="Fornavn *"
											autoComplete="given-name"
											value={formData.contactDetails.firstName}
											onChange={handleChange}
											inputName="firstName"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.firstName.length > 0
													? "border-b-1 border-[#00174B]"
													: touchedFirstName
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
										/>
									</div>
									<div className="mb-2 md:mb-4">
										<TextInput
											tabIndex={2}
											disabled={false}
											name="Efternavn *"
											autoComplete="family-name"
											value={formData.contactDetails.lastName}
											onChange={handleChange}
											inputName="lastName"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.lastName.length > 0
													? "border-b-1 border-[#00174B]"
													: touchedLastName
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										tabIndex={3}
										disabled={false}
										name="Email *"
										autoComplete="email"
										value={formData.contactDetails.email}
										onChange={handleChange}
										inputName="email"
										className={`w-full border p-2 text-sm md:text-base ${
											validateEmail(formData.contactDetails.email) ? "border-b-1 border-[#00174B]" : touchedEmail ? "border-b-1 border-red-500" : ""
										}`}
										formIndex="contactDetails"
										type="email"
									/>
								</div>
								<div className="mb-2 md:mb-4">
									<label className="block text-gray-700 text-sm font-bold">Telefonnummer *</label>
									<div className="grid grid-cols-10">
										<input
											type="text"
											name="prefix"
											value="+45"
											disabled
											className="col-span-2 md:col-span-1 w-full border p-2 text-sm md:text-base"
										/>
										<input
											tabIndex={4}
											type="text"
											name="phone"
											autoComplete="tel"
											value={formData.contactDetails.phone}
											onChange={(e) => handleChange(e, "contactDetails")}
											className={`col-span-8 md:col-span-9 w-full border p-2 text-sm md:text-base ${
												validatePhone(formData.contactDetails.phone)
													? "border-b-1 border-[#00174B]"
													: touchedPhone
													? "border-b-1 border-red-500"
													: ""
											}`}
											placeholder="XXXXXXXX"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										tabIndex={5}
										disabled={false}
										name="Adresse *"
										autoComplete="address-line1"
										value={formData.contactDetails.address}
										onChange={handleChange}
										inputName="address"
										className={`w-full p-2 border ${addressAccepted ? "border-b-1 border-[#00174B]" : ""}`}
										formIndex="contactDetails"
									/>
									<ul role="list" className={`divide-y divide-gray-200 px-2 ${foundAddresses.length > 0 ? "border" : ""}`}>
										{foundAddresses.map((item, index) => (
											<li key={index} className="flex justify-between gap-x-6 py-2" onClick={() => pickAddress(item)}>
												<div className="flex min-w-0 gap-x-4">
													<div className="min-w-0 flex-auto">
														<p className="text-sm font-semibold leading-6 text-gray-900">{item.address}</p>
													</div>
												</div>
												<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
													<button onClick={() => pickAddress(item)} className="text-sm leading-6  text-white px-4 rounded bg-[#00174B]">
														Vælg
													</button>
												</div>
											</li>
										))}
									</ul>
								</div>
								<div className="grid grid-cols-2 gap-2">
									<div className="mb-2 md:mb-4">
										<TextInput
											disabled={false}
											name="By *"
											autoComplete="address-level2"
											value={formData.contactDetails.city}
											onChange={handleChange}
											inputName="city"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.city.length > 0 ? "border-b-1 border-[#00174B]" : touchedCity ? "border-b-1 border-red-500" : ""
											}`}
											formIndex="contactDetails"
											placeholder="Greve"
										/>
									</div>
									<div className="mb-2 md:mb-4">
										<TextInput
											disabled={false}
											name="Postnummer *"
											autoComplete="postal-code"
											value={formData.contactDetails.postalCode}
											onChange={handleChange}
											inputName="postalCode"
											className={`w-full border p-2 text-sm md:text-base ${
												postalCodeVerified
													? "border-b-1 border-[#00174B]"
													: touchedPostalCode && !isPostalCodeValid
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
											placeholder="XXXX"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										disabled={false}
										toolTip={[
											"Du finder registreringsmærkaten",
											"med en ottecifret kode direkte på dit BWT-anlæg.",
											"Kan du ikke finde produktkoden, så kan du skrive 0000-0000",
										]}
										name="Produktkode *"
										value={formData.installationDetails.serialNumber}
										onChange={handleChange}
										inputName="serialNumber"
										className={`w-full border p-2 text-sm md:text-base ${
											validateProductCode(formData.installationDetails.serialNumber)
												? "border-b-1 border-[#00174B]"
												: touchedSerialNumber
												? "border-b-1 border-red-500"
												: ""
										}`}
										formIndex="installationDetails"
										placeholder="XXXX-XXXX"
									/>
								</div>

								{shallTypeInstallationDate === true ? (
									<div className="mb-2 md:mb-4">
										<label className="block text-gray-700 text-sm font-bold">Købs-/dato for idriftsættelse *</label>
										<div className={`border px-2 ${formData.installationDetails.installationDate != null ? "border-b-1 border-[#00174B]" : ""}`}>
											<DatesProvider settings={{ consistentWeeks: true, locale: "da", firstDayOfWeek: 1, timezone: "GMT" }}>
												<DatePickerInput
													className="bg-white"
													size="md"
													leftSectionPointerEvents="none"
													placeholder="Vælg dato"
													value={formData.installationDetails.installationDate}
													onChange={(value) => {
														setFormData((prevData) => ({
															...prevData,
															["installationDetails"]: {
																...prevData["installationDetails"],
																["installationDate"]: value,
															},
														}));
													}}
													radius="0"
													maxDate={new Date()}
													variant="unstyled"
													valueFormat="DD/MM/YYYY"
													// onKeyDown={(event) => {
													// 	event.preventDefault();
													// }}
												/>
											</DatesProvider>
										</div>
									</div>
								) : null}

								{contactDetailErrors.length > 0 ? (
									<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
										<ul>
											{contactDetailErrors.map((error, index) => (
												<li key={index}>{error}</li>
											))}
										</ul>
									</div>
								) : null}

								{techniciansUnavailableInPostal || isOffshore ? (
									<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
										<h1 className="font-semibold">
											Det er desværre ikke muligt for dig at bestille en tid. Du skal derfor kontakte kundeservice på telefon +45 43 600 500 for at
											kunne bestille dit servicebesøg.
										</h1>
									</div>
								) : null}

								<button
									type="button"
									onClick={() => {
										setOpenIssues(true);
										setOpenContactDetails(false);
										setIssuesCompleted(false);
										setContactDetailsCompleted(false);
									}}
									className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]"
								>
									Tilbage
								</button>
								<button
									type="button"
									disabled={isContactDetailsValid()}
									onClick={() => {
										if (shallTypeInstallationDate === true) {
											setOpenContactDetails(false);
											setContactDetailsCompleted(true);
											setOpenCustomerDetails(true);
											contactDetailsRef.current?.scrollIntoView();
										} else {
											findServiceAggrements();
										}
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</form>
						</div>
					</div>
					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 ">
						<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							<StepHeader
								num="3"
								name="Tryghedsaftale"
								completed={openDateTimePicker}
								onClick={() => {
									// Open the step again if it is not open
									if (openDateTimePicker) {
										setOpenIssues(false);
										setOpenContactDetails(false);
										setIssuesCompleted(true);
										setContactDetailsCompleted(true);
										setOpenCustomerDetails(true);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{customerDetailsCompleted === false ? (
									<svg
										className={`h-5 w-5 flex-none text-gray-400 transform ${
											openCustomerDetails ? "rotate-0" : "rotate-180"
										} transition-transform duration-300`}
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`text-gray-600 mt-6 ${hidden(openCustomerDetails && customerDetailsCompleted === false)}`}>
							{!isEligibleForFreeServiceAggrement() && formData.customerDetails.hasAggreementType ? (
								<>
									{formData.installationDetails.error == "almservice" &&
									capitalizeFirstLetter(formData.customerDetails.hasAggreementType) !== "Premium" ? (
										<div>
											<h1 className="font-semibold">
												Dit anlæg er registreret og dækket af en BWT Tryghed Plus aftale. Et almindeligt serviceeftersyn er <u>ikke</u> inkluderet
												i en Plus eller Basic aftale.
											</h1>
											{serviceAggrementMsg ===
											"Du er allerede dækket af en BWT tryghed " +
												formData.customerDetails.hasAggreementType +
												" aftale, som betyder at dit servicebesøg er dækket." ? (
												<h1 className="font-semibold">Tryk Næste, hvis du fortsat ønsker at få dit anlæg efterset af en BWT tekniker.</h1>
											) : null}
										</div>
									) : (
										<div className="mb-2">
											<h1 className="font-semibold">
												Dit anlæg er registreret og dækket af en BWT Tryghed {capitalizeFirstLetter(formData.customerDetails.hasAggreementType)}{" "}
												aftale, og servicebesøget af derfor inkluderet.
											</h1>
											{serviceAggrementMsg ===
											"Du er allerede dækket af en BWT tryghed " +
												formData.customerDetails.hasAggreementType +
												" aftale, som betyder at dit servicebesøg er dækket." ? (
												<h1 className="font-semibold">Tryk Næste for at finde en ledig dato og tidspunkt</h1>
											) : null}
										</div>
									)}
								</>
							) : null}

							{!isEligibleForFreeServiceAggrement() &&
							!formData.customerDetails.hasAggreementType &&
							serviceAggrementMsg === "Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?" ? (
								<div>
									<h1 className="font-semibold">{serviceAggrementMsg}</h1>
								</div>
							) : null}

							{isEligibleForFreeServiceAggrement() ? (
								<div>
									<h1 className="font-semibold">
										Ud fra den indtastede Købs-/dato for idriftsættelse, er dit anlæg ved registrering dækket af en BWT Tryghed Premium aftale.
									</h1>
								</div>
							) : null}

							{!isEligibleForFreeServiceAggrement() && serviceAggrementMsg == "" ? (
								<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
									<h1 className="font-semibold">
										Det er desværre ikke muligt for dig at bestille en tid. Du skal derfor kontakte kundeservice på telefon +45 43 600 500 for at
										kunne bestille dit servicebesøg.
									</h1>
								</div>
							) : null}

							{loadingServiceAggrement ? <div className="loader w-10 h-10" /> : null}

							{serviceAggrementMsg === "Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?" ||
							serviceAggrementMsg ===
								"Vi kan se du tidligere har haft en BWT tryghedsaftale. Ønsker du at genoptage denne eller vil du betale for det enkelte besøg?" ? (
								<div>
									{!isEligibleForFreeServiceAggrement() ? (
										<Carousel responsive={responsive} partialVisible={true}>
											<div key={1} className="border text-center py-4 px-1 mr-1">
												<img className="m-auto h-52" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/premium.webp" alt="" />
												<h3 className="text-md font-semibold my-2">TRYGHED PREMIUM</h3>
												<ul className="text-sm text-left">
													{isEligibleForFreeServiceAggrement() ? (
														<li>
															✓ Første 2 år GRATIS<sup>1</sup>
														</li>
													) : null}
													<li>
														✓ Fast servicebesøg hvert 2. år<sup>1</sup>
													</li>
													<li>✓ Reservedele inkl.</li>
													<li>✓ Sliddele inkl.</li>
													<li>✓ Arbejdstid og kørsel inkl.</li>
													<li>✓ Gratis låneanlæg ved fejl på anlæg</li>
													<li>✓ Nyt anlæg efter 10 år</li>
													{!isEligibleForFreeServiceAggrement() ? <li>&nbsp;</li> : null}
												</ul>

												<p className="text-sm text-left font-bold mt-2">
													{isEligibleForFreeServiceAggrement() ? (
														<span>Gratis ved valg af BWT Tryghed Premium aftale</span>
													) : (
														<span>
															Månedlig pris<sup>3</sup> {getAggrementPrice(formData.installationDetails.model, "premium")} kr.{" "}
														</span>
													)}
												</p>
												<p className="text-xs text-left mt-2">
													{isEligibleForFreeServiceAggrement() ? (
														<span>Herefter {getAggrementPrice(formData.installationDetails.model, "premium")} kr. pr. måned</span>
													) : (
														<span>
															Mindste pris i 12 mdr.{" "}
															{((getAggrementPrice(formData.installationDetails.model, "premium") * 12) / 1000).toFixed(3)} kr.{" "}
														</span>
													)}
												</p>
												<button
													className={`w-full py-2 my-2 hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
														formData.orderDetails.wantServiceAggrement === "premium"
															? "bg-white text-black"
															: "text-white bg-[#00174B] hover:text-black hover:bg-white"
													}`}
													onClick={() => {
														setTryghedPlusText("Vælg");
														setTryghedPremiumText("Valgt");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: "premium",
															},
														}));
													}}
												>
													{tryghedPremiumText}
												</button>
											</div>
											<div key={2} className="border text-center py-4 px-1 mr-1">
												<img className="m-auto h-52" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/plus.webp" alt="" />
												<h3 className="text-md font-semibold my-2">TRYGHED PLUS</h3>
												<ul className="text-sm text-left">
													<li>✓ Reservedele inkl.</li>
													<li>✓ Arbejdstid og kørsel inkl.</li>
													<li>✓ Gratis låneanlæg ved fejl på anlæg</li>
													<li>
														<span className="text-red-500">✕</span> Standard serviceeftersyn hvert 2 år
													</li>
													<li>
														<span className="text-red-500">✕</span> Eksklusiv sliddele
													</li>
													<li>
														<span className="text-red-500">✕</span> Nyt anlæg efter 10 år<sup>2</sup>
													</li>
													<li>&nbsp;</li>
												</ul>

												<p className="text-sm text-left font-bold mt-2">
													Månedlig pris<sup>3</sup> {getAggrementPrice(formData.installationDetails.model, "plus")} kr.
												</p>
												<p className="text-xs text-left mt-2">
													Mindste pris i 12 mdr. {((getAggrementPrice(formData.installationDetails.model, "plus") * 12) / 1000).toFixed(3)} kr.
												</p>
												<button
													className={`w-full py-2 my-2 hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
														formData.orderDetails.wantServiceAggrement === "plus"
															? "bg-white text-black"
															: "text-white bg-[#00174B] hover:text-black hover:bg-white"
													}`}
													onClick={() => {
														setTryghedPlusText("Valgt");
														setTryghedPremiumText("Vælg");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: "plus",
															},
														}));
													}}
												>
													{tryghedPlusText}
												</button>
											</div>
										</Carousel>
									) : (
										<Carousel responsive={responsiveMobile} partialVisible={true}>
											<div key={1} className="border text-center py-4 px-1 mr-1">
												<img className="m-auto h-52" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/premium.webp" alt="" />
												<h3 className="text-md font-semibold my-2">TRYGHED PREMIUM</h3>
												<ul className="text-sm text-left">
													{isEligibleForFreeServiceAggrement() ? (
														<li>
															✓ Første 2 år GRATIS<sup>1</sup>
														</li>
													) : null}
													<li>
														✓ Fast servicebesøg hvert 2. år<sup>1</sup>
													</li>
													<li>✓ Reservedele inkl.</li>
													<li>✓ Sliddele inkl.</li>
													<li>✓ Arbejdstid og kørsel inkl.</li>
													<li>✓ Gratis låneanlæg ved fejl på anlæg</li>
													<li>✓ Nyt anlæg efter 10 år</li>
													{!isEligibleForFreeServiceAggrement() ? <li>&nbsp;</li> : null}
												</ul>

												<p className="text-sm text-left font-bold mt-2">
													{isEligibleForFreeServiceAggrement() ? (
														<span>Gratis ved valg af BWT Tryghed Premium aftale</span>
													) : (
														<span>
															Månedlig pris<sup>3</sup> {getAggrementPrice(formData.installationDetails.model, "premium")} kr.{" "}
														</span>
													)}
												</p>
												<p className="text-xs text-left mt-2">
													{isEligibleForFreeServiceAggrement() ? (
														<span>Herefter {getAggrementPrice(formData.installationDetails.model, "premium")} kr. pr. måned</span>
													) : (
														<span>
															Mindste pris i 12 mdr.{" "}
															{((getAggrementPrice(formData.installationDetails.model, "premium") * 12) / 1000).toFixed(3)} kr.{" "}
														</span>
													)}
												</p>
												<button
													className={`w-full py-2 my-2 hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
														formData.orderDetails.wantServiceAggrement === "premium"
															? "bg-white text-black"
															: "text-white bg-[#00174B] hover:text-black hover:bg-white"
													}`}
													onClick={() => {
														setTryghedPlusText("Vælg");
														setTryghedPremiumText("Valgt");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: "premium",
															},
														}));
													}}
												>
													{tryghedPremiumText}
												</button>
											</div>
										</Carousel>
									)}
									<div className="my-2 text-sm">
										*<sup>1</sup>, <sup>2</sup>, <sup>3</sup> Læs betingelser og vilkår, lige{" "}
										<a
											className="text-[#00174B] font-bold underline hover:text-[#E5006B]"
											href="https://www.bwt.com/da-dk/kundeservice/serviceaftaler/tryghedsaftaler-til-private/"
											target="_blank"
										>
											her
										</a>
										.
									</div>
									<Checkbox
										title="Nej tak, jeg ønsker ikke at registrere mit anlæg og accepterer at betale for det enkelte servicebesøg, såfremt den konstaterede fejl ikke er dækket af garantien"
										value={formData.orderDetails.wantServiceAggrement === "none" ? true : false}
										undertext="(Pris for standard servicebesøg er fra 3300 kr. inkl. Moms)"
										onChange={() => {
											setTryghedPlusText("Vælg");
											setTryghedPremiumText("Vælg");
											setFormData((prevData) => ({
												...prevData,
												["orderDetails"]: {
													...prevData["orderDetails"],
													["wantServiceAggrement"]: "none",
												},
											}));
										}}
									/>
								</div>
							) : null}

							<button
								type="button"
								onClick={() => {
									setOpenCustomerDetails(false);
									setOpenContactDetails(true);
									setContactDetailsCompleted(false);
								}}
								className="text-white px-4 py-2 mr-4 mt-4 rounded bg-[#666B6E]"
							>
								Tilbage
							</button>
							<button
								type="button"
								disabled={serviceAggrementIsValid()}
								onClick={() => {
									setOpenDateTimePicker(true);
									setOpenCustomerDetails(false);
									setCustomerDetailsCompleted(true);
								}}
								className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
							>
								Næste
							</button>
						</div>
					</div>
					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-b-lg">
						<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
							<StepHeader num="4" name="Dato og Tid" onClick={() => {}} />
							<span className="ml-2">
								<svg
									className={`h-5 w-5 flex-none text-gray-400 transform ${
										openDateTimePicker ? "rotate-0" : "rotate-180"
									} transition-transform duration-300`}
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							{/* </a> */}
						</div>
						<div className={`${hidden(openDateTimePicker && customerDetailsCompleted === true && contactDetailsCompleted === true)} mt-6`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<div className={`text-gray-600 items-center flex justify-center`}>
									<DatesProvider settings={{ consistentWeeks: true, locale: "da-DK", firstDayOfWeek: 1, timezone: "GMT" }}>
										<DatePicker
											className="align-center"
											minDate={getDateInFuture(1)}
											maxDate={getDateInFuture(180)}
											allowDeselect
											value={formData.orderDetails.date}
											onChange={(date) => handleDateChanged(date)}
											getDayProps={getDayProps}
											getYearControlProps={getYearControlProps}
											getMonthControlProps={getMonthControlProps}
										/>
									</DatesProvider>
								</div>
								<div className="mt-2 text-sm">
									{formData.orderDetails.date !== null ? (
										<div className="border p-2 text-center bg-white shadow-lg">
											Ledige tider
											<br />{" "}
											{formData.orderDetails.date !== null
												? moment(formData.orderDetails.date.toISOString().split("T")[0]).format("DD-MM-YYYY")
												: ""}
										</div>
									) : (
										<div className="border p-4 text-center bg-white shadow-lg">Vælg en dato for at se ledige tider</div>
									)}

									<div className="grid grid-cols-1 gap-2 my-4">
										{formData.orderDetails.date !== null && availableTimeSlots.length > 0
											? availableTimeSlots.map((item) => (
													<button
														className={`w-full  py-2  hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
															time === item.timeSlot ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"
														}`}
														onClick={() => {
															setTime(item.timeSlot);
															setBookingId(item.id);
														}}
													>
														{item.timeSlot}
													</button>
											  ))
											: null}

										{formData.orderDetails.date !== null && availableTimeSlots.length === 0 ? (
											<p className="text-center">Ingen ledige tider</p>
										) : null}
									</div>
								</div>
							</div>

							{/*<Checkbox
								title="Jeg accepterer at BWT må kontakte mig telefonisk, hvis der er en ledig tid før den valgte dato"
								value={formData.orderDetails.callForQuickOrder ? true : false}
								onChange={() => {
									setFormData({
										...formData,
										orderDetails: {
											...formData.orderDetails,
											callForQuickOrder: !formData.orderDetails.callForQuickOrder,
										},
									});
								}}
							/>*/}

							<div className="relative flex mb-4">
								<textarea
									id="message"
									rows={2}
									className="block p-2.5 w-full text-sm mt-3 text-gray-900 bg-gray-50 rounded border border-gray-300 dark:border-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
									placeholder="Har du nogle sidste bemærkninger til din booking?"
									value={formData.installationDetails.message}
									name="message"
									onChange={(e) => handleChange(e, "installationDetails")}
								></textarea>
							</div>

							<div className="mt-8">
								<button
									type="button"
									onClick={() => {
										setOpenCustomerDetails(true);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);

										// setOpenContactDetails(true);
										// setOpenDateTimePicker(false);
										// setContactDetailsCompleted(false);
									}}
									className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]"
								>
									Tilbage
								</button>
								<button
									disabled={formData.orderDetails.date === null || time === null} // If date and time is not selected, then disable the button
									type="button"
									onClick={() => {
										setOpenDateTimePicker(true);
										setOpenContactDetails(false);
										setContactDetailsCompleted(true);
										setIsModalOpen(true);
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</div>
							<Modal
								isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement}
								setFormData={setFormData}
								isOpen={isModalOpen}
								onClose={closeModal}
								formData={formData}
								date={formData.orderDetails.date}
								time={time}
								onSubmit={handleSubmit}
								isLoading={isBookingLoading}
								getAggrementPrice={getAggrementPrice}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<p>Tak for din booking. Du vil indenfor 2 hverdage modtage en ordrebekræftelse</p>
						<span>
							<CheckCircle className="w-6 h-6 stroke-green-500" />
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default StepForm;

// StepHeader component
const StepHeader: React.FC<{ num: string; name: string; completed?: boolean; onClick: () => void }> = ({ num, name, completed = false, onClick }) => {
	return (
		<div className={`flex items-center ${completed ? "hover:cursor-pointer" : ""} `} onClick={onClick}>
			<p className="text-md md:text-lg w-5">{num}</p>
			<p className="text-md md:text-lg">{name}</p>
		</div>
	);
};

// component for displaying a possible solution to the user for the selected error and model
const Solution: React.FC<{ formData: FormData; fejlOptions: Record<string, Option[]> }> = ({ formData, fejlOptions }) => {
	let model = formData.installationDetails.model;
	let error = formData.installationDetails.error;

	return (
		<>
			{error !== "other" ? (
				<div className="bg-gray-50 border p-4 rounded-lg my-4">
					{model === "aqabasic" && error === "saltbeholdertor" ? (
						<div className="mt-4">
							<img className="mb-2" src="basicvandkarret.png" alt="" />
						</div>
					) : null}

					{/* PERLA ANLÆG */}
					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "almservice" ? (
						<div className="">
							<p className="text-gray-900 font-semibold">Et almindeligt serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Kontrol af installation</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskiftning af klorcelle (sliddel)</li>
								<li>- Vandanalyse og korrekte indstillinger af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome") && error === "kalderforsalt" ? (
						<div>
							<p className="text-gray-900 font-semibold">Det ser ud til at du skal have en servicetekniker på besøg.</p>
						</div>
					) : null}

					{model === "bwtperlaone" && error === "kalderforsalt" ? (
						<div>
							<p className="text-gray-900 font-semibold">Det ser ud til at du skal have en servicetekniker på besøg.</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "ekspertservice" ? (
						<div>
							<p className="text-gray-900 font-semibold">
								Dit Perla anlæg har behov for et Ekspert Service hvert 24. måned, og her skal du booke et servicebesøg.
							</p>
							<br />
							<p className="text-gray-900 font-semibold">Et Ekspert serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Opdatering af anlægget til den nyeste version</li>
								<li>- Gennemgået om målt efter (reg. på begge sider)</li>
								<li>- Sat til at lave et Ekspert Service med IoClean</li>
								<li>- Tester AQA stop om det virker som det skal</li>
								<li>- Kontrol af installation</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskift af slid- og reservedele</li>
								<li>- Udskift af klorcelle (sliddel)</li>
								<li>- Vandanalyse og korrekt indstilling af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "positionsfejlmotorfejl" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Forsøg at trække stikket ud og vent i 15 min. Herefter vil anlægget genstarte hele systemet og fejlen skulle gerne forsvinde.
							</p>
							<br />
							<p className="">Hvis fejlen ikke forsvinder, så er der behov for en BWT servicetekniker</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "taellerikkened" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Tjek om dit bypass er lukket - Hvis det står åbent løber vandet uden om anlægget, vandet bliver derfor ikke blødgjort, og displayet tæller
								derved ikke ned.
							</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "vandisalgbeholder" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Der må ikke være vand i saltbeholderen. Anlægget sørger selv for at opløse de salttabs der ligger i saltbeholderen</p>
							<br />
							<p className="">Der er derfor behov for at du booker en BWT servicetekniker til at udbedre fejlen</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "rutinemaessigvedligehold" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag:</p>
							<p className="">
								Dit Perla anlæg vil bede om Rutinemæssig vedligehold hvert 6. måned. Dette sker for at sikre en høj hygiejne i anlægget. Her skal du bruge
								en IoClean, og hvis du ikke har sådan en, kan du købe en pakke på vores webshop{" "}
								<a href="https://shop.bwt.dk/webshop/salt-og-tilbehoer-til-bloedgoeringsanlaeg/bwt-ioclean" target="_blank">
									her
								</a>
							</p>
							<br />
							<p className="text-gray-900 font-semibold">Du kan følge step-by-step guiden, som vises på displayet eller denne quick guide:</p>
							<div className="">
								<img className="mb-2" src="booking_perla1.png" alt="" />
								<img className="mb-2" src="booking_perla2.png" alt="" />
								<img className="mb-2" src="booking_perla3.png" alt="" />
								<img className="mb-2" src="booking_perla4.png" alt="" />
							</div>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "regenereringsvolumenikkenået" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Grunden til denne melding kan være fordi et par af salttabletterne laver en ”saltbro”. Forsøg at røre rundt så langt ned i saltbeholderen
								du kan med f.eks. en grydeske, og husk at fjerne beskeden fra displayet og app.
								<br />
								Hvis meldingen kommer igen, så er der behov for en BWT servicetekniker.
							</p>
						</div>
					) : null}

					{/* AQA Life og AQA basic */}

					{(model === "aqalife" || model === "aqabasic") && error === "almservice" ? (
						<div className="">
							<p className="text-gray-900 font-semibold">Et almindeligt serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Kontrol af installation</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskift af slid- og reservedele</li>
								<li>- Udskiftning af klorcelle (sliddel)</li>
								<li>- Vandanalyse og korrekte indstillinger af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "kalderforsalt" ? (
						<div className="">
							{model === "aqalife" ? (
								<p>
									Hvis anlægget skriver ”Salt” eller ”lack of salt” i displayet har klorcellen ikke registreret salt i det vand den suger op i filteret,
									under regenerationen.
								</p>
							) : (
								<p>
									Hvis anlægger skriver 'Salt' eller 'lack of salt' i displayet har klorcellen ikke registreret salt i det vand den suger op i filteret
									under regenerationen. Eller også har anlægget ikke suget noget saltvand op.
								</p>
							)}
							<br />
							<ul className="text-gray-600">
								<li>- Tjek om der er salt og vand i anlægget.</li>
								<li>- Tjek om der er knæk på slangerne til og fra anlægget.</li>
								<li>- Tjek om haner på bypass til anlægget står åbent.</li>
								<li>- Tjek at samtlige stik er tilsluttet korrekt, inkl. klorcelle</li>
							</ul>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "p05error" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Forsøg at trykke OK på anlægget indtil alarmen forsvinder. Når fejlen er forsvundet, fjern strømmen til anlægget og tilslut den igen efter
								10 sekunder. Hvis dette ikke virker er der behov for at booke et servicebesøg.
							</p>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "service" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Når dit BWT-anlæg viser 'Wartung', betyder det, at det anbefales at anlægget vedligeholdes eller serviceres. Det er en automatisk timer i
								anlægget der kommer med påmindelsen om service. Vi anbefaler altid et servicebesøg for at opretholde høj hygiejne og forlængelse af
								anlæggets levetid.
								<br />
								<br />
								Ønsker du ikke service kan du: <br />
								Nulstille ved at holde ok knappen inde i ca. 7 sekunder. Servicekaldet er tids- eller regenerations bestemt, så anlægget vil fortælle dig
								hvornår det er tid til service igen.
							</p>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && (error === "skylleriafloeb" || error === "taellerikkened") ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							{error === "skylleriafloeb" ? (
								<p className="">
									Du skal by-passe dit anlæg og booke et servicebesøg. Der er fejl på anlægget som skal udbedres af en BWT tekniker før anlægget virker
									korrekt igen.
								</p>
							) : (
								<p className="">
									Tjek om dit bypass er lukket - Hvis det står åbent løber vandet uden om anlægget, vandet bliver derfor ikke blødgjort, og displayet
									tæller derved ikke ned.
								</p>
							)}

							<div className="relative flex flex-col items-center max-w-screen-xl mx-auto md:flex-row">
								<div className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:pr-10">
									<div className="text-left">
										<p className="text-gray-900 font-semibold">Sådan by-passer du dit anlæg</p>
										<p className="max-w-md mx-auto mt-3 text-base text-gray-500 md:mt-5 md:max-w-3xl">
											Der er typisk lukkehaner som på billedet:
											<br />
											Her ser du et bypass der betegnes som lukket. dvs. at de 2 haner der er tilsluttet anlægget, er åbne så vandet løber igennem
											og den sidste hane mellem de 2 andre er lukket. På denne måde ledes vandet gennem anlægget og der leveres blødt vand til
											husstanden.
											<br />
											Tommelfingerregel: Vandet løber samme vej som bladene på hanen vender. Altså, er hanen drejet så bladene går langs med røret
											er hanen åben og vandet kan løbe igennem. Er hanen på tværs af rørene kan vandet ikke løbe igennem og hanen er derfor lukket.
										</p>
									</div>
								</div>
								<div className="flex items-center md:w-1/2 ">
									<div className="relative w-full p-3 rounded  md:p-8">
										<div className="rounded-lg bg-white text-black w-full">
											<img src="image.png" />
										</div>
										<div className="rounded-lg bg-white text-black w-full">
											<img src="Picture1.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "saltbeholdertor" ? (
						<div>
							<p className="">
								Der skal altid være vand i saltbeholderen på dit anlæg. Vandstanden kan variere løbende.
								<br />
								<br />
								Du har brug for et teknikerbesøg, da vi har behov for at komme ud og udbedre fejlen.
								<br />
								<br />
								Indtil vores tekniker kommer, vil vi bede dig om at hælde ca. 5 liter vand i saltbeholderen, og holde øje med at anlægget ikke løber tør.
								<br />
							</p>
						</div>
					) : null}

					{/* KUN AQA basic */}

					{model === "aqabasic" && error === "ikkevand" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Har du tjekket at:</p>
							<ul className="text-gray-600">
								<li>- Der er vand i karet?</li>
								<li>- Der er salt i karet?</li>
								<li>- Klorstikket sidder i?</li>
							</ul>
						</div>
					) : null}

					{model === "aqabasic" && error === "maalerikkehaardheder" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Det ser ud til at du skal have en servicetekniker på besøg.</p>
						</div>
					) : null}

					<br />

					<div className="">
						<p className="text-gray-600">Fortsæt videre for at bestille en BWT servicetekniker</p>
					</div>
				</div>
			) : null}
		</>
	);
};
