import React, { useState, useEffect, FormEvent } from "react";
import axios from "axios";
import moment from "moment";
import Checkbox from "./Components/Checkbox";
import { useLocation } from "react-router-dom";
import CheckCircle from "./Components/Icons/CheckCircle";
import { DatePicker, DatesProvider, DatePickerProps, getMonthDays, DateValue } from "@mantine/dates";
import { ITechnicianBooking, PickServiceDateModel } from "./types/types";
import "./App.css";
import "dayjs/locale/da";
import "react-multi-carousel/lib/styles.css";

const API = "https://integration.bwt.dk/api";
// const API = "http://localhost:8080/api";

const initialFormState: PickServiceDateModel = {
	bookService: {
		customerNumber: null,
		AS400OrderId: null,
		LEVLQB: null,
		customer: null,
		postalCode: null,
		anlaegsType: null,
		userId: null,
		phone: null,
		OPDTD: null,
		OPDTS: null,
		fileUploadedToSD: null,
		technicianPrivateServiceBookingId: null,
		createdAt: null,
		updatedAt: null,
	},
	date: null,
	time: null,
	message: null,
	callForQuickOrder: null,
};

const BookService: React.FC = () => {
	const [formData, setFormData] = useState<PickServiceDateModel>(initialFormState);

	const [time, setTime] = useState<string | null>(null);
	const [serviceBookingId, setServiceBookingId] = useState<number | null>(null);
	const [bookingId, setBookingId] = useState<number | null>(null);
	const [technicianAvailableDates, setTechnicianAvailableDates] = useState<[string] | []>([]);
	const [availableTimeSlots, setAvailableTimeSlots] = useState<[ITechnicianBooking] | []>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [bookingHasBeenSent, setBookingHasBeenSent] = useState(false);
	const location = useLocation();

	const [loadingServiceBooking, setLoadingServiceBooking] = useState<boolean>(true);
	const [serviceBookingFound, setServiceBookingFound] = useState<boolean>(false);

	const [anlaegsType, setAnlaegsType] = useState<string | null>(null);

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		let { search } = location;
		let id: string = "",
			phone: string = "";
		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("id" in searchJSON) {
				id = searchJSON["id"];
			}
			if ("phone" in searchJSON) {
				phone = searchJSON["phone"];
			}

			if (id && phone) {
				fetchServiceBooking(id, phone);
				setServiceBookingId(parseInt(id));
			} else {
				setLoadingServiceBooking(false);
			}
		}
	}, []);

	// Henter dage hvor teknikeren har 1 eller flere ledige tider
	useEffect(() => {
		console.log(getMonthDays);
		const fetchData = async () => {
			const date = formData.date;
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : "";
				const response = await axios.get(`${API}/technicianbooking/all/${formData.bookService.userId}/${_date}`);
				setTime(null);
				setBookingId(null);
				setAvailableTimeSlots(response.data.teknikerBookings);
				console.log("available time slots:", response.data.teknikerBookings);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.date !== null && isModalOpen !== true && formData.bookService.userId !== null) {
			fetchData();
		}
	}, [formData.date, formData.bookService.userId]);

	// Henter ledige tider når kunden vælger en dato
	useEffect(() => {
		console.log(getMonthDays);
		const fetchData = async () => {
			const date = formData.date;
			const postalCode = formData.bookService.postalCode;
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : "";
				const response = await axios.get(
					`${API}/technicianbooking/all/${formData.bookService.userId}/${_date}/${postalCode}/${formData.bookService.anlaegsType}`
				);
				setTime(null);
				setBookingId(null);
				setAvailableTimeSlots(response.data.teknikerBookings);
				console.log("available time slots:", response.data.teknikerBookings);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.date !== null && isModalOpen !== true && formData.bookService.userId !== null) {
			fetchData();
		}
	}, [formData.date, formData.bookService.userId, formData.bookService.postalCode]);

	// Her hender vi teknikerens ledige datoer
	useEffect(() => {
		const fetchData = async () => {
			try {
				const postalCode = formData.bookService.postalCode;

				const response = await axios.get(
					`${API}/technicianbooking/availabledates/${formData.bookService.userId}/${postalCode}/${formData.bookService.anlaegsType}/servicebooking`
				);
				setTechnicianAvailableDates(response.data.teknikerBookingDates);
				console.log(response.data);
				console.log("Henter datoer igen for teknikeren " + formData.bookService.userId + " og postnummer " + postalCode);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};

		if (formData.bookService.userId !== null && formData.date === null) {
			fetchData();
		}
	}, [formData.bookService.postalCode, formData.bookService.userId]); // Empty array as the second argument to run effect only once (on mount)

	const fetchServiceBooking = async (id: string, phone: string) => {
		try {
			const response = await axios.get(`${API}/servicebooking/${id}/${phone}`);

			setFormData({
				...formData,
				bookService: {
					...formData.bookService,
					customerNumber: response.data.serviceBooking.customerNumber,
					AS400OrderId: response.data.serviceBooking.AS400OrderId,
					LEVLQB: response.data.serviceBooking.LEVLQB,
					customer: response.data.serviceBooking.customer,
					postalCode: response.data.serviceBooking.postalCode,
					anlaegsType: response.data.serviceBooking.anlaegsType,
					userId: response.data.serviceBooking.userId,
					phone: response.data.serviceBooking.phone,
					OPDTD: response.data.serviceBooking.OPDTD,
					OPDTS: response.data.serviceBooking.OPDTS,
					fileUploadedToSD: response.data.serviceBooking.fileUploadedToSD,
					technicianPrivateServiceBookingId: response.data.serviceBooking.technicianPrivateServiceBookingId,
					createdAt: response.data.serviceBooking.createdAt,
					updatedAt: response.data.serviceBooking.updatedAt,
				},
			});
			setServiceBookingFound(true);
		} catch (error) {
			console.error("Error fetching dates:", error);
			setServiceBookingFound(false);
		}
		setLoadingServiceBooking(false);
	};

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		console.log("booking id is: " + bookingId);

		const body = {
			date: formData.date ? moment(formData.date.toISOString().split("T")[0]).format("YYYY-MM-DD") : null,
			timeSlot: time,
			userId: formData.bookService.userId,
			customerNumber: formData.bookService.customerNumber,
			phone: formData.bookService.phone,
			postal: formData.bookService.postalCode,
			message: formData.message,
			acceptedContactByBWT: formData.callForQuickOrder,
			model: formData.bookService.anlaegsType,
		};

		try {
			const response = await axios.post(API + "/servicebooking/" + bookingId + "/" + serviceBookingId, body);
			console.log(response);
			setIsModalOpen(false);
			setBookingHasBeenSent(true);
		} catch (error) {
			console.log(error);
			setIsModalOpen(false);
		}
	};

	function getDateInFuture(amount: number): Date {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() + amount);
		return yesterday;
	}

	const getDayProps: DatePickerProps["getDayProps"] = (_date) => {
		// If the day is saturday or sunday, then disable it
		if (_date.getDay() === 6 || _date.getDay() === 0) {
			return { disabled: true };
		}

		// console.log(technicianAvailableDates);

		if (technicianAvailableDates === undefined) {
			return { disabled: true };
		}

		if (technicianAvailableDates.length <= 0) {
			return { disabled: true };
		}

		const available = technicianAvailableDates.some((item) => item === _date.toISOString().split("T")[0]);

		const date = formData.date;

		if (date !== null && _date !== null) {
			if (_date.toISOString().split("T")[0].replace(/-/g, "") === date.toISOString().split("T")[0].replace(/-/g, "")) {
				return {
					style: {
						backgroundColor: "#00174B",
						color: "var(--mantine-color-white)",
						selected: "red",
					},
				};
			}
		}

		if (available) {
			return {
				style: {
					backgroundColor: "#00174B",
					opacity: 0.72,
					color: "var(--mantine-color-white)",
					selected: "red",
				},
			};
		} else {
			return { disabled: true };
		}
	};

	// Hvis året er det samme som i dag, så gør teksten blå og fed på årsknapperne
	const getYearControlProps: DatePickerProps["getYearControlProps"] = (date) => {
		if (date.getFullYear() === new Date().getFullYear()) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getFullYear() === new Date().getFullYear() + 1) {
			return { disabled: true };
		}

		return {};
	};

	// Hvis måneden er februar, så gør teksten blå og fed på månedknapperne
	const getMonthControlProps: DatePickerProps["getMonthControlProps"] = (date) => {
		if (date.getMonth() === 1) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getMonth() === 5) {
			return { disabled: true };
		}

		return {};
	};

	const handleDateChanged = (date: DateValue) => {
		setFormData({
			...formData,
			date: date,
		});
	};

	return (
		<>
			<div className="max-w-3xl mx-auto mt-4 mb-4 md:mb-6 px-1 md:px-0">
				<div className="max-h-8 md:max-h-12 flex h-screen">
					<div>
						<img className="max-h-8 md:max-h-14" src="./BWT_Logo_with_Subline_Positive.png" alt="bwtchangetheworldlogo" />
					</div>
					<div className="m-auto mr-0 ">
						<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">Bestil BWT servicetekniker</h3>
					</div>
				</div>
			</div>

			{loadingServiceBooking ? (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<div className={`my-4`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<Loader color="red" />
							</div>
						</div>
					</div>
				</div>
			) : null}

			{!loadingServiceBooking && !serviceBookingFound ? (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<p>Du har allerede booket dit næste servicebesøg</p>
						<span>
							<CheckCircle className="w-6 h-6 stroke-green-500" />
						</span>
					</div>
				</div>
			) : null}

			{!bookingHasBeenSent && !loadingServiceBooking && serviceBookingFound ? (
				<div>
					<div className="border-x border max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-lg">
						<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
							<div className={`flex items-center`}>
								<p className="text-md md:text-lg">Vælg dato og tid for dit næste servicebesøg</p>
							</div>
						</div>
						<div>
							<h1 className="">
								Det er nu blevet tid til det faste servicebesøg i og med du har en BWT Tryghed Premium aftale. Vælg venligst en dato og tid, hvor vores
								BWT servicetekniker kan komme forbi og servicere dit anlæg.
							</h1>
						</div>
						<div className={` mt-6`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<div className={`text-gray-600 items-center flex justify-center`}>
									<DatesProvider settings={{ consistentWeeks: true, locale: "da-DK", firstDayOfWeek: 1, timezone: "GMT" }}>
										<DatePicker
											className="align-center"
											minDate={getDateInFuture(1)}
											maxDate={getDateInFuture(180)}
											allowDeselect
											value={formData.date}
											onChange={(date) => handleDateChanged(date)}
											getDayProps={getDayProps}
											getYearControlProps={getYearControlProps}
											getMonthControlProps={getMonthControlProps}
										/>
									</DatesProvider>
								</div>
								<div className="mt-2 text-sm">
									{formData.date !== null ? (
										<div className="border p-2 text-center bg-white shadow-lg">
											Ledige tider
											<br /> {formData.date !== null ? moment(formData.date.toISOString().split("T")[0]).format("DD-MM-YYYY") : ""}
										</div>
									) : (
										<div className="border p-4 text-center bg-white shadow-lg">Vælg en dato for at se ledige tider</div>
									)}

									<div className="grid grid-cols-1 gap-2 my-4">
										{formData.date !== null && availableTimeSlots.length > 0
											? availableTimeSlots.map((item) => (
													<button
														className={`w-full  py-2  hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
															time === item.timeSlot ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"
														}`}
														onClick={() => {
															setTime(item.timeSlot);
															setBookingId(item.id);
														}}
													>
														{item.timeSlot}
													</button>
											  ))
											: null}

										{formData.date !== null && availableTimeSlots.length === 0 ? <p className="text-center">Ingen ledige tider</p> : null}
									</div>
								</div>
							</div>

							<Checkbox
								title="Jeg accepterer at BWT må kontakte mig telefonisk, hvis der er en ledig tid før den valgte dato"
								value={formData.callForQuickOrder ? true : false}
								onChange={() => {
									setFormData({
										...formData,
										callForQuickOrder: !formData.callForQuickOrder,
									});
								}}
							/>

							<div className="relative flex mb-4">
								<textarea
									id="message"
									rows={2}
									className="block p-2.5 w-full text-sm mt-3 text-gray-900 bg-gray-50 rounded border border-gray-300 dark:border-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
									placeholder="Har du nogle sidste bemærkninger til din booking?"
									value={formData.message ?? ""}
									name="message"
									onChange={(e) => setFormData({ ...formData, message: e.target.value })}
								></textarea>
							</div>

							<div className="mt-8">
								<button
									disabled={formData.date === null || time === null} // If date and time is not selected, then disable the button
									type="button"
									onClick={() => {
										setIsModalOpen(true);
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</div>
						</div>
						<SimpleModal
							isOpen={isModalOpen}
							onClose={closeModal}
							formData={formData}
							date={formData.date}
							time={time}
							onSubmit={handleSubmit}
							isLoading={false}
						/>
					</div>
				</div>
			) : null}

			{bookingHasBeenSent ? (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<p>Tak for din booking</p>
						<span>
							<CheckCircle className="w-6 h-6 stroke-green-500" />
						</span>
					</div>
				</div>
			) : null}
		</>
	);
};

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	formData: PickServiceDateModel;
	date: Date | null;
	time: String | null;
	onSubmit: (e: FormEvent) => void;
	isLoading: boolean;
}

// Min Modal
const SimpleModal: React.FC<ModalProps> = ({ isOpen, onClose, formData, date, time, onSubmit, isLoading }) => {
	return (
		<>
			{isOpen && (
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-screen">
						<div className="fixed inset-0 transition-opacity" onClick={onClose}>
							<div className="absolute inset-0 bg-black opacity-50"></div>
						</div>
						<div className="relative bg-white py-5 md:py-10 px-2 md:px-8 rounded-lg max-w-2xl">
							<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
								<p className="text-sm md:text-xl font-semibold">Bekræft venligst at nedenstående er korrekt</p>
							</div>
							<div className="mt-4">
								<div className={`text-gray-600`}>
									{date ? (
										<p>
											Dato <b>{date.toISOString().split("T")[0]}</b> Tid <b>{time}</b>
										</p>
									) : null}

									<button className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]" onClick={onClose}>
										Annuller
									</button>

									{isLoading ? (
										<div className="loader w-10 h-10" />
									) : (
										<button
											disabled={date === null || time === null} // If date and time is not selected, then disable the button
											type="button"
											className="text-white px-4 py-2 rounded bg-[#00174B] disabled:bg-[#666B6E] mt-2"
											onClick={onSubmit}
										>
											Bestil
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const Loader: React.FC<{ color: string }> = ({ color }) => {
	return (
		<div className="flex justify-center items-center">
			<div className={`loader ease-linear rounded-full border-8 border-t-8 border-${color}-200 h-24 w-24`}></div>
		</div>
	);
};

export default BookService;
